@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  body {
    background: linear-gradient(
      158.86deg,
      #1c2a34 10.36%,
      #111a20 49.58%,
      #111a20 49.58%
    );
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @apply m-0 font-jost min-h-screen;
  }

  code {
    font-family: "Courier New", monospace;
  }

  a {
    @apply text-primary hover:underline underline-offset-4;
  }

  p {
    @apply mb-4
  }

  .evaluation {
    @apply grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4;
  }
}
